import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stripeKey: null,
    cardData: null,
    tokenData: null,
};

const paymentSlice = createSlice({
    name: "payment",
    initialState: initialState,
    reducers: {
        setTokenData(state, action) {
            state.tokenData = action.payload;
        },
        loadUserCardData(state, action) {
            state.cardData = action.payload;
        },
        resetCardData(state, action) {
            state.cardData = action.payload;
        },
        setStripeKey(state, action) {
            state.stripeKey = action.payload;
        }
    }
});

export const paymentActions = paymentSlice.actions;

export default paymentSlice;