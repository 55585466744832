import { createTheme } from "@material-ui/core";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.palette.primary.main = "#3D5B2B";
theme.palette.secondary.main = "#FF8D01";

// export const theme = createTheme({
//     palette: {
//         primary: {
//           main: "#3D5B2B",
//         },
//         secondary: {
//           main: "#FF8D01",
//         },
//       },
//       typography: {
//         h1: {
//           fontSize: '1.2rem',
//   '@media (min-width:600px)': {
//     fontSize: '1.5rem',
//         }
//       },
//       []
//     }
// })

export default theme;