import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import AuthContext from "../../../store/auth-context";

export default function UserProtectedRoute({
  component: Component,
  ...restOfProps
}) {
  const authCtx = useContext(AuthContext);
  const isAuthenticated = authCtx.isLoggedIn;

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}
