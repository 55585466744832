import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newAppointmentList: [],
  userScheduledAppointments: [],
  availableTechs: [],
  selectedAppointment: null,
  appointmentDateAndTime: null,
  selectedAppointmentOrderDetails: null,
  error: false,
  loading: false,
};

const appointmentSlice = createSlice({
  name: "appointments",
  initialState: initialState,
  reducers: {
    loadNewAppointmentsForReview(state, action) {
      state.newAppointmentList = action.payload;
    },
    setSelectedAppointment(state, action) {
      state.selectedAppointment = action.payload;
    },
    loadUserRequestedAppointments(state, action) {
      state.userScheduledAppointments = action.payload;
    },
    setSelectedAppointmentOrderDetails(state, action) {
      state.selectedAppointmentOrderDetails = action.payload;
    },
    setAvailableTechs(state, action) {
      state.availableTechs = action.payload;
    },
    setAppointmentDateAndTime(state, action) {
      state.appointmentDateAndTime = action.payload;
    },
    setLoadingState(state) {
      state.loading = !state.loading;
    },
    getUserAppointmentWithId(state, action) {
      const arrayElement = state.userScheduledAppointments.find(
        (x) => x.id === action.payload
      );
      state.selectedAppointment = arrayElement;
    },
    removeAppointmentFromList(state, action) {
      const arrayElement = state.userScheduledAppointments.find(
        (x) => x.id === action.payload
      );
      state.userScheduledAppointments = state.userScheduledAppointments.filter(
        (element) => element.id !== arrayElement.id
      );
      state.selectedAppointment = null;
    },
  },
});

export const appointmentActions = appointmentSlice.actions;

export default appointmentSlice;
