import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import appUserSlice from "./app-user-slice";
import appointmentSlice from "./appointment-slice";
import paymentSlice from "./payment-slice";

const reducers = combineReducers({
  appUser: appUserSlice.reducer,
    appointment: appointmentSlice.reducer,
    payment: paymentSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
