import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import UserProtectedRoute from "./components/Layout/Routes/UserProtectedRoute";
import Spinner from "./components/UI/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getStripeKey } from "./store/actions/payment-actions";

const AppointmentSetupWrapper = React.lazy(() =>
  import("./containers/Appointment/AppointmentSetup")
);
const AppUserAuth = React.lazy(() =>
  import("./containers/Auth/Onboarding/AppUserAuth")
);
const AppUserSignUp = React.lazy(() =>
  import("./containers/Auth/Onboarding/AppUserSignUp")
);
const ResetPassword = React.lazy(() =>
  import("./containers/AppUser/ResetPassword")
);
const AppUser = React.lazy(() => import("./containers/AppUser/AppUser"));
const Appointment = React.lazy(() =>
  import("./containers/Appointment/Appointment")
);
const VerificationSuccess = React.lazy(() =>
  import("./components/UI/Forms/CardPayments/VerificationSuccess")
);
const VerificationFailed = React.lazy(() =>
  import("./components/UI/Forms/CardPayments/VerificationFailed")
);

function App() {
  const [pageLoadingState, setPageLoadingState] = useState(false);
  const dispatch = useDispatch();
  const stripeApiKey = useSelector((state) => state.payment.stripeKey);

  useEffect(() => {
    if (!stripeApiKey) {
      async function getStripeInfo() {
        setPageLoadingState(true);
        try {
          await dispatch(getStripeKey());
          setPageLoadingState(false);
        } catch (err) {
          setPageLoadingState(false);
        }
      }
      getStripeInfo();
    }
  }, [dispatch, stripeApiKey]);

  if (pageLoadingState === true) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <Layout>
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <Switch>
          <Route
            path="/new-appointment"
            exact
            component={AppointmentSetupWrapper}
          />

          <Route path="/" exact component={AppUserAuth} />
          <Route path="/signup" exact component={AppUserSignUp} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <UserProtectedRoute path="/profile" exact component={AppUser} />
          <UserProtectedRoute
            path="/appointment/:appointmentId"
            exact
            component={Appointment}
          />
          <UserProtectedRoute
            path="/success"
            exact
            component={VerificationSuccess}
          />
          <UserProtectedRoute
            path="/reauth"
            exact
            component={VerificationFailed}
          />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
