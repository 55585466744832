import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import classes from "./Header.module.css";
import logo from "../../../assets/images/circular-logo.png";

const Header = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.isLoggedIn;

  const logoutButtonClickHandler = () => {
    authContext.logout();
    history.replace("/");
  };

  const loginButtonClickHandler = () => {
    history.push("/");
  };

  const newAppointmentHandler = () => {
    history.push("/new-appointment");
  };

  return (
    <header className={classes.header}>
      <a href="https://palva.org">
        <img src={logo} alt="Palva" />
      </a>

      <div className={classes.auth}>
        <button onClick={newAppointmentHandler} className={classes.button}>
          New Appointment
        </button>

        <button
          onClick={
            isLoggedIn ? logoutButtonClickHandler : loginButtonClickHandler
          }
          className={classes.button}
        >
          {isLoggedIn ? "Logout" : "Login / Register"}{" "}
        </button>
      </div>
    </header>
  );
};

export default Header;
