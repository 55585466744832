import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  selectedUser: null,
  loggedInUser: null,
};

const appUserSlice = createSlice({
  name: "appUser",
  initialState: initialState,
  reducers: {
    loadTechApplications(state, action) {
      state.users = action.payload;
    },
    setSelectedUSer(state, action) {
      state.selectedUser = action.payload;
    },
    removeUserFromList(state, action) {
      const arrayElement = state.users.find(x => x.id === action.payload);
     state.users = state.users.filter((element) => element.id !== arrayElement.id);
      state.selectedUser = null;
    },
    setLoggedInUser(state, action) {
      state.loggedInUser = action.payload;
    }
  },
});

export const appUserActions = appUserSlice.actions;

export default appUserSlice;
