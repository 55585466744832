export const generateHeaders = () => {
  return {
    Authorization: localStorage.getItem("authorization"),
    "Content-type": "application/json",
    Accept: "application/json",
    "X-Forwarded-Protocol": "https",
  };
};

export const generateUrl = (data) => {
 return new URL(data, "https://polar-citadel-32172.herokuapp.com/");
 // return new URL(data, "http://localhost:8080/");
};

export const statePattern = "^((A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$";

