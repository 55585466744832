import axios from "axios";
import { generateHeaders, generateUrl } from "../../utils/constants";
import { paymentActions } from "../payment-slice";


export const createCustomerStripeAccount = async (
  cardToken,
  userId,
  cardHolderName
) => {
  const tokenId = cardToken.id;
  const requestBody = {
    tokenId: tokenId,
  };
  const url = generateUrl(
    `payment/create-customer/${userId}/${cardHolderName}`
  );
  try {
    const response = await axios.post(url, requestBody);
    if (response.data.error) {
      throw new Error(response.data.error.message);
    }
    return response.status;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createPlebStripeAccount = async (cardToken, userId) => {
  let result;
  const tokenId = cardToken.id;
  const requestBody = {
    tokenId: tokenId,
  };
  const url = generateUrl(`payment/create-connect/${userId}`);
  const headers = generateHeaders();
  try {
    const response = await axios.post(url, requestBody, { headers });
    if (response.data) {
      //Check if response data has error
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        result = response;
      }
    } else {
      result = response;
    }
    return result;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchUserPaymentAccountDetails = (appUser) => {
  const userId = appUser.id;
  return async (dispatch) => {
    const fetchCardData = async () => {
      const url =
        appUser.role === "PATIENT"
          ? generateUrl(`payment/retrieve-card/${userId}`)
          : generateUrl(`payment/retrieve-debitcard/${userId}`);
      const headers = generateHeaders();
      const response = await axios.get(url, { headers });
      if (response.status === 204) {
        return null;
      } else if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        return response.data;
      }
    };
    try {
      const cardData = await fetchCardData();
      dispatch(paymentActions.loadUserCardData(cardData));
    } catch (er) {
      throw new Error(er.message);
    }
  };
};

export const refreshAccountLinkToken = async (userId) => {
  try {
    const url = generateUrl(`payment/createLink/${userId}`);
    const headers = generateHeaders();
    const response = await axios.post(url, { headers });
    if (response.data.error) {
      throw new Error(response.data.error.message);
    } else {
      return response;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deletePatientPaymentCard = (userId) => {
  return async (dispatch) => {
    const deletePaymentCard = async () => {
      const url = generateUrl(`payment/card-delete/${userId}`);
      const headers = generateHeaders();
      const response = await axios.delete(url, { headers });
      if (response.data || response.status === 204) {
        throw new Error(response.data.error.message);
      } else {
        return response.status;
      }
    };
    try {
      const deleteStatus = await deletePaymentCard();
      if (deleteStatus === 200) {
        dispatch(paymentActions.resetCardData(null));
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const deletePhlebPaymentCard = (userId) => {
  return async (dispatch) => {
    const deletePaymentCard = async () => {
      const url = generateUrl(`payment/delete-debitcard/${userId}`);
      const headers = generateHeaders();
      const response = await axios.delete(url, { headers });
      if (response.data) {
        throw new Error(response.data.error.message);
      } else {
        return response.status;
      }
    };
    try {
      const deleteStatus = await deletePaymentCard();
      if (deleteStatus === 200) {
        dispatch(paymentActions.resetCardData(null));
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getStripeKey = () => {
  return async (dispatch) => {
    const getKey = async () => {
      const url = generateUrl("payment/getkey");
      const response = await axios.get(url);
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        return response.data.key;
      }
    };
    try {
      const data = await getKey();
      dispatch(paymentActions.setStripeKey(data));
    } catch (e) {
      throw new Error(e.message);
    }
  };
};
